<template>
  <div>
    <d-button
      :text="selectedModel ? 'admin.duplicate.calendar.without-model' : 'admin.duplicate.calendar.with-model'"
      class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
      @on:button-click="scrollToCategoryList"
    />

    <calendar-list
      v-if="selectedModel || getSelectedCalendar"
    />

    <div v-if="getSelectedCalendar" class="border-bottom-grey mt-4 mb-4"/>

    <calendar-list-duplicate
      v-show="!selectedModel || getSelectedCalendar"
      id="calendarListDuplicateId"
    />
  </div>
</template>
<script>
import {getClubs} from "@api/services/club/club.api";
import CalendarList from "@views/duplicate/calendar/CalendarList";
import CalendarListDuplicate from "@views/duplicate/calendar/CalendarListDuplicate";

export default {
  components: {CalendarList, CalendarListDuplicate},
  data: () => ({
    duplicationModel: false,
    search: '',
    withModel: false,
    selectedModel: false,
  }),
  computed: {
    getSelectedCalendar() {
      return this.$store.getters['calendar/getSelectedCalendarModel'];
    }
  },
  methods: {
    scrollToCategoryList() {
      this.selectedModel = !this.selectedModel;

      this.$nextTick(() => {
        this.$store.commit('calendar/setSelectedCalendarModel', null);
        setTimeout(() => {
          document.getElementById('calendarListDuplicateId').scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 300)
        ;
      });
    },
  },
  mounted() {
    this.$store.commit("calendar/setCheckedCalendarList", []);
    this.$store.commit("calendar/setSelectedCalendarModel", null);
  }
}
</script>
